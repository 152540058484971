import xhr, { concurrencyManager } from "../client/xhr";
import { AuthenticationSession, AuthMethod } from "./Domain";
import { AxiosResponse, CancelToken } from "axios";

class AuthenticationService {

    public getSession(extendSession?: boolean, cancelToken? : CancelToken) : Promise<AuthenticationSession> {
        const options : { params: {[name: string]: string}, cancelToken? : CancelToken } = { params: { } };
        let concurrencyChanged = false;
        if (extendSession) {
            options.params.extendSession = "true";
            // this limits number of concurrent requests on axios to 1
            // that's here to prevent race condition between cookie/localStorage values
            concurrencyManager.setConcurrency(1);
            concurrencyChanged = true;
        }
        if (cancelToken) {
            options.cancelToken = cancelToken;
        }
        return xhr.get('/gateway/auth/me', options)
            .then(response => new AuthenticationSession(response.data))
            .finally(() => concurrencyChanged && concurrencyManager.resetConcurrency());
    }

    public async getMethods() : Promise<AuthMethod[]> {
        const response : AxiosResponse = await xhr.get('/gateway/auth/methods', { params: { hostname: window.location.hostname } })
        return response.data as AuthMethod[];
    }
}

export default new AuthenticationService();
