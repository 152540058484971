import xhr from '../client/xhr';
import {
    FlowDocument,
    Flow,
    Document,
    FlowCounters,
    LockingInfo,
    ImportedData,
    Attachment,
    SourcesForAccumulate,
    DocumentRange
} from "./DocumentDomain";
import {ITemplateInfo, ITemplateSchema} from '../documenttemplate/Domain';
import CacheMap from '../commons/cache/CacheMap';
import { AuthenticatedUser } from '../authentication/Domain';
import { accountService } from '../account/AccountService';
import { FlowTemplate } from '../templates/Domain';
import templatesService from '../templates/templatesService';
import { AxiosProgressEvent } from 'axios';

export interface DocumentAssets {
    document: Document,
    templateInfo: ITemplateInfo,
    schema: ITemplateSchema,
    imports: ImportedData
}

export interface DocumentFlowAssets extends DocumentAssets {
    flow: Flow,
    flowDocument: FlowDocument,
    flowTemplate: FlowTemplate,
}

export type InitFlowRequest = {
    flowTemplateId?: string,
    option: string,
    sourceIds: Array<{flowId: string, documentIds: string[]}>,
    partnerId: string,
}

export type AddDocumentRequest = {
    stepIndex: number,
    option: string,
    sourceIds: Array<{flowId: string, documentIds: string[]}>
}

export default class DocumentService {
    private static instance: DocumentService;
    private expressionLibrary? : string;
    private flowTemplateMap: CacheMap<FlowTemplate>;
    private documentTemplateMap: CacheMap<{schema:ITemplateSchema, info:ITemplateInfo}>;
    private flowMap: CacheMap<Flow>;
    private documentMap: CacheMap<Document>;
    private flowTemplateLoadingMap: Map<string,{loading:boolean,waiting:Array<{resolve:any,reject:any}>}>;
    private flowLoadingMap: Map<string,{loading:boolean,waiting:Array<{resolve:any,reject:any}>}>;
    private documentLoadingMap: Map<string,{loading:boolean,waiting:Array<{resolve:any,reject:any}>}>;


	private constructor() {
        // sizes for caches of objects
        this.flowTemplateMap = new CacheMap(10);
        this.documentTemplateMap = new CacheMap(10);
        this.flowMap = new CacheMap(20);
        this.documentMap = new CacheMap(20);
        this.flowTemplateLoadingMap = new Map();
        this.flowLoadingMap = new Map();
        this.documentLoadingMap = new Map();
    }

	public static getInstance(): DocumentService {
        if (!DocumentService.instance) {
            DocumentService.instance = new DocumentService();
        }
        return DocumentService.instance;
    }

    public retrieveExpressionLibrary() : Promise<string> {
        if (!this.expressionLibrary) {
            return xhr.get(`/gateway/documents/expressions/library`)
            .then(l => {
                this.expressionLibrary = l.data;
                return this.expressionLibrary!;
            });
        }
        return Promise.resolve(this.expressionLibrary);
    }

    public getLock(docId:string) : Promise<LockingInfo | undefined> {
        return xhr.get('/gateway/documents/lockings/documents/'+docId).then(lockResponse => lockResponse.data);
    }

    public createRenewLock(docId:string) : Promise<LockingInfo> {
        let request = {documentId: docId, authName:''};
        return xhr.post('/gateway/documents/lockings/lock',request)
        .then(lockResponse => {
            let lockingInfo:LockingInfo = lockResponse.data;
            return lockingInfo;
        });
    }

    public markDocumentRead(flowId: string, documentId: string) : Promise<{flow: Flow, flowDocument: FlowDocument}> {
        return xhr.post(`/gateway/documents/flows/${flowId}/documents/${documentId}/markRead`)
            .then(flowResponse => {
                let flow = new Flow(flowResponse.data);
                return ({ flow: flow, flowDocument: flow.getDocument(documentId)! });
            });
    }

    public lockDocument(documentId : string, user: AuthenticatedUser) : Promise<LockingInfo> {
        return this.getLock(documentId)
            .then(lockingInfo => {
                if (lockingInfo) {
                    console.log("locking found ", lockingInfo);
                    //update for same user the lock
                    if (lockingInfo.authName === user.authName) {
                        console.log("renew lock for user");
                        return this.createRenewLock(documentId);
                    }
                    else {
                        return accountService.getAccount(user.accountId, user.organization, user.tenantId)
                            .then(account => {
                                let timediffms = (new Date().getTime() - new Date(lockingInfo.created).getTime());
                                //update lock to new user if expired
                                if (timediffms > account.lockingTimeout) {
                                    console.log("foreign lock %s expired %d ms taking over", lockingInfo.authName, account.lockingTimeout);
                                    return this.createRenewLock(documentId);
                                } //else no update real lock
                                else {
                                    console.log("foreign lock active");
                                    return lockingInfo;
                                }
                            });
                    }
                }
                else {
                    console.log("locking not found set as new");
                    return this.createRenewLock(documentId);
                }
            });
    }

    public unlockDocument(documentId : string) : Promise<void> {
        return xhr.delete(`/gateway/documents/lockings/unlock/${documentId}`)
            .then(() => {
                console.log("locking deleted ", documentId);
            })
            .catch(error => {
                console.error("Problem occured when unlocking document.", error);
                if (error.status !== 404) {
                    throw error;
                }
            });
    }

    public openDocumentDetail(flowId: string, documentId: string, user: AuthenticatedUser) : Promise<(DocumentFlowAssets & {lock? : LockingInfo})> {
        return this.retrieveDocumentAssets(flowId, documentId)
            .then(assets => {
                // mark document in unread state as read
                if (assets.flowDocument.state === 'UNREAD') {
                    return this.markDocumentRead(flowId, documentId)
                        .then((markResult) => ({...assets, flow: markResult.flow, flowDocument: markResult.flowDocument}));
                }
                return assets;
            })
            .then(assets => {
                // if document is a draft then lock the document
                if (assets.flowDocument.state === 'DRAFT') {
                    return this.lockDocument(documentId, user).then((lock) => ({...assets, lock: lock}));
                }
                return assets;
            });
    }

    public updateDocument(assets : DocumentFlowAssets, data : {[k: string]: any}) : Promise<void> {
        return xhr.post(`/gateway/documents/flows/${assets.flow._id}/documents/${assets.document.id}/update`, { data: data });
    }

    public exportDocument(assets : DocumentFlowAssets) : Promise<{data:Blob}> {
        return xhr.get(`/gateway/documents/flows/${assets.flow._id}/documents/${assets.document.id}/export`,{responseType: 'blob'});
    }

    public importDocumentToNewFlow(file: Blob, flowTemplateId: string, partnerId: string, onUploadProgress: ((e: AxiosProgressEvent) => void), controller: AbortController) {
        let formData = new FormData();
        formData.append("file", file);
        return xhr.post("/gateway/documents/document-import",
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "flowTemplateId": flowTemplateId,
                    "partnerId": partnerId
                },
                onUploadProgress: onUploadProgress,
                signal: controller.signal
            });
    }

    public importDocumentToExistingFlow(file: Blob, stepIndex: number, flowId: string, onUploadProgress: ((e: AxiosProgressEvent) => void), controller: AbortController) {
        let formData = new FormData();
        formData.append("file", file);
        return xhr.post(`/gateway/documents/document-import/${flowId}`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "stepIndex": stepIndex
                },
                onUploadProgress: onUploadProgress,
                signal: controller.signal
            });
    }

    public replaceDocumentByImport(file: Blob, documentId: string, flowId: string, onUploadProgress: ((e: AxiosProgressEvent) => void), controller: AbortController) {
        let formData = new FormData();
        formData.append("file", file);
        return xhr.post(`/gateway/documents/document-import/${flowId}/documents/${documentId}`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                onUploadProgress: onUploadProgress,
                signal: controller.signal
            });
    }

    public sendDocument(assets : DocumentFlowAssets, data : {[k: string]: any}) : Promise<{ newAssets?: DocumentFlowAssets, response?: any }> {
        return this.sendOrRedoDocument(assets, data, false);
    }

    public redoDocument(assets : DocumentFlowAssets, data : {[k: string]: any}) : Promise<{ newAssets?: DocumentFlowAssets, response?: any }> {
        return this.sendOrRedoDocument(assets, data, true);
    }

    private sendOrRedoDocument(assets : DocumentFlowAssets, data : {[k: string]: any}, redo : boolean) : Promise<{ newAssets?: DocumentFlowAssets, response?: any }> {
        return xhr.post(`/gateway/documents/flows/${assets.flow._id}/documents/${assets.document.id}/${redo ? 'redo' :'send'}`, { data: data })
            .then(sendResponse => {
                return Promise.allSettled([
                    this.retrieveDocumentAssets(assets.flow._id, assets.document.id),
                    this.unlockDocument(assets.document.id)
                ]).then(([newAssets, v]) => {
                    return { status: "SENT", newAssets: newAssets.status === 'fulfilled' ? (newAssets as any).value : undefined, response: sendResponse.data };
                })
            });
    }

    public resendDocument(assets : DocumentFlowAssets) : Promise<void> {
        return xhr.post(`/gateway/documents/flows/${assets.flow._id}/documents/${assets.document.id}/resend`)
        .then(() => {
            console.log("document scheduled for resend ", assets.document.id);
        })
        .catch(error => {
            console.error("Problem occured when scheduling for resend.", error);
            throw error;
        });
    }

	public retrieveDocumentAssets(flowId: string, documentId: string) : Promise<DocumentFlowAssets> {
        let flowPromise : Promise<any> = this.retrieveFlow(flowId,false)
            .then(f => {
                let flow = f;
                let flowDoc = flow.getDocument(documentId);
                if (!flowDoc) {
                    return undefined;
                }
                return Promise.all([
                   this.retrieveFlowTemplate(flow.flowTemplateId),
                   this.retrieveDocumentTemplate(flowDoc.documentTemplateId)
                ]).then(([ft,dt]) => ({flow: flow, flowTemplate: ft, flowDocument: flowDoc, schema: dt.schema, templateInfo: dt.info}));
            });
        let documentPromise : Promise<Document> = this.retrieveDocument(documentId, flowId, false);
        let importedDataPromise : Promise<ImportedData> = this.retrieveImportedData(documentId,flowId);

		return Promise.all([
            flowPromise,
            documentPromise,
            importedDataPromise
        ])
        .then(([fds, d, id]) => (fds && d) ? {...fds, document: d, imports: id || []} : undefined)
        .catch(error => {
            console.error("Problem occured when loading data.", error);
            throw error;
        });
    }

    public retrieveAvailableSourceDocuments(flowId : string, stepIndex: number, optionIndex: number) : Promise<string[][]> {
        return xhr.get(`/gateway/documents/flows/${flowId}/steps/${stepIndex}/options/${optionIndex}/availableSourceDocuments`)
            .then(response => {
                return response.data;
            });
    }

    public async getAccumulateSources(flowTemplateId : string, stepIndex: number, optionIndex: number) : Promise<SourcesForAccumulate[]> {
        const response = await xhr.get(`/gateway/documents/flow-templates/${flowTemplateId}/steps/${stepIndex}/options/${optionIndex}/accumulate-sources`);
        return response.data.map((dataEntry : any) => new SourcesForAccumulate(dataEntry));

    }

    public createDocumentAndInitFlow(createDocumentRequest : InitFlowRequest) : Promise<{flowId : string, documentId : string}> {
        return xhr.post(`/gateway/documents/flows`, createDocumentRequest)
            .then(response => {
                return (response as any).data;
            });
    }

    public addDocumentToFlow(createDocumentRequest : AddDocumentRequest, flowId : string) : Promise<{flowId : string, documentId : string}> {
        return xhr.post(`/gateway/documents/flows/${flowId}/documents`, createDocumentRequest)
            .then(response => {
                return (response as any).data;
            });
    }

    public retrieveFlow(flowId:string,useCache:boolean) : Promise<Flow> {
        return this.retrieveObject(flowId,useCache,this.flowMap,this.flowLoadingMap,"flow",xhr.get("/gateway/documents/flows/"+flowId, {timeout: 30000}).then(l => new Flow(l.data)));
    }

    public retrieveDocument(documentId:string,flowId:string,useCache:boolean) : Promise<Document> {
        return this.retrieveObject(documentId,useCache,this.documentMap,this.documentLoadingMap,"document",xhr.get(`/gateway/documents/flows/${flowId}/documents/${documentId}`, {timeout: 30000}).then(l => new Document(l.data)));
    }

    public retrieveFlowTemplate(flowTemplateId:string) : Promise<FlowTemplate> {
        return this.retrieveObject(flowTemplateId, true, this.flowTemplateMap, this.flowTemplateLoadingMap, "flowTemplate", templatesService.getFlowTemplate(flowTemplateId));
    }

    public retrieveImportedData(documentId:string,flowId:string) : Promise<ImportedData> {
        let url = `/gateway/documents/data-imports/${flowId}/${documentId}`;
        return xhr.get(url).then(r => r.data as ImportedData);
    }

    // TODO find sollution to also use the generic function
    public retrieveDocumentTemplate(documentTemplateId:string) : Promise<{schema: ITemplateSchema, info: ITemplateInfo}> {
        if (!this.documentTemplateMap.get(documentTemplateId)) {
            console.log('retrieving documentTemplate new ',documentTemplateId);
            return templatesService.getDocumentTemplate(documentTemplateId)
            .then(t => {
                this.documentTemplateMap.set(documentTemplateId, {schema: t.data as ITemplateSchema, info: t as ITemplateInfo});
                return this.documentTemplateMap.get(documentTemplateId)!;
            });
        }
        console.log('retrieving documentTemplate from cache',documentTemplateId);
        return Promise.resolve(this.documentTemplateMap.get(documentTemplateId)!);
    }

    public resetDocumentTemplateCache(documentTemplateId: string): void {
        this.documentTemplateMap.delete(documentTemplateId);
    }

    public retrieveFlowsWithTemplates(showAll : boolean, sortField : string, sortDirection : "ASC" | "DESC", searchString:string|undefined, limit : number, firstId? : string, lastId? : string, steps?: string[], dateField?:string, dateFrom?: Date|null, dateTo?: Date|null ) : Promise<{ flow : Flow, template: FlowTemplate }[]> {
        let url = '/gateway/documents/flows' +
            '?showAll=' + showAll +
            '&sortField=' + sortField +
            '&sortDirection=' + sortDirection +
            '&limit=' + limit;

        if (firstId) {
            url += '&firstId=' + firstId;
        }
        if (lastId) {
            url += '&lastId=' + lastId;
        }
        if (searchString) {
            url += '&query=' + searchString;
        }
        if (steps) {
            url += `&steps=${encodeURIComponent(steps.join(","))}`;
        }
        if (dateField && (dateFrom ?? dateTo)) {
            url += `&dateField=${dateField}`;
            if (dateFrom) {
                url += `&dateFrom=${dateFrom.toISOString()}`;
            }
            if (dateTo) {
                 url += `&dateTo=${dateTo.toISOString()}`;
            }
        }
        return xhr.get(url)
		    .then(r => Promise.all(r.data.map((f : any) => this.retrieveFlowTemplate(f.flowTemplateId).then(ft => ({flow: new Flow(f), template: ft})))));
    }

    public retrieveObject<T>(id: string, useCache: boolean, objectMap: CacheMap<T>, loadingMap: Map<string,{waiting:Array<{resolve:any,reject:any}>}>, name: string, retrieveObject: Promise<T>) : Promise<T> {
        console.log("objectmap:",objectMap);
        console.log("loadingmap:",loadingMap);

        if (!id) {
            console.log(name+" undefined",id);
            return Promise.reject("no "+name);
        }
        if (useCache && objectMap.get(id)) {
            return Promise.resolve(objectMap.get(id)!);
        }
        if (loadingMap.get(id)) {
            return new Promise<T>((resolve, reject) => {
                loadingMap.get(id)!.waiting.push({resolve: resolve, reject: reject});
            });
        }

        loadingMap.set(id,{waiting:[]});
        console.log("retrieving "+name+" new ",id);
        return retrieveObject.then(obj => {
            // resolve all waiting
            loadingMap.get(id)?.waiting.forEach((el) => {
                console.log("finished waiting for "+name+" retrieved",id,el);
                el.resolve(obj);
            });
            loadingMap.delete(id);
            // set new value to the cache (even if useCache is false)
            objectMap.set(id,obj);
            return obj;
        });
    }

    getCounters():Promise<FlowCounters> {
        return xhr.get("/gateway/documents/statistics/counters", {timeout:30000})
        .then(resp => {
            return new FlowCounters(resp.data);
        })
    }

    public deleteDraftDocumentFromFlow(flowId:string,documentId:string) : Promise<Flow> {
            return xhr.delete(`/gateway/documents/flows/${flowId}/documents/${documentId}`).then(r => r.data);
    }

    public deleteAttachment(attachmentId : string) : Promise<void> {
        return xhr.delete(`/gateway/documents/attachments/${attachmentId}`)
        .then(() => {
            console.log("attachment deleted ", attachmentId);
        })
        .catch(error => {
            console.error("Problem occured when deleteing attachment.", error);
            if (error.status !== 404) {
                throw error;
            }
        });

    }

    public listAttachment(attachmentId : string) : Promise<Attachment[]> {
        return xhr.get(`/gateway/documents/attachments/list/${attachmentId}`).then((response : any) => Object.values(response.data).map((a) => new Attachment(a)));
    }

    public getRanges() : Promise<DocumentRange[]> {
        return xhr.get(`/gateway/documents/ranges`).then((response:any) => Object.values(response.data).map((a) => new DocumentRange(a)));
    }

    public getNextSequenceNumber(sequence: string) : Promise<number> {
        return xhr.get(`/gateway/documents/ranges/${sequence}`).then((rangeResponse) => rangeResponse.data)
    }
}

export const documentService = DocumentService.getInstance();
