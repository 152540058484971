import { buildGatewayUrl } from "../client/xhr";
import { PUBSUB_SSE_EVENTS_TOPIC, STORAGE_TOKEN_NAME } from "../commons/CommonConstants";
import { SystemNotification } from "./NotificationsDomain";

export default class NotificationClient {
	private opened : boolean;
	private eventSource? : EventSource;


	constructor() {
		this.opened = false;
	}

	open() : void {
		const token = localStorage.getItem(STORAGE_TOKEN_NAME);
		this.eventSource = new EventSource(buildGatewayUrl("/gateway/notifications/listen?access_token=" + token), { withCredentials: true });
		this.eventSource.addEventListener("message", this.onNotificationMessage);
		this.eventSource.addEventListener("error", this.onNotificationError);
		this.opened = true;
	}

	close() : void {
		this.eventSource?.removeEventListener("message", this.onNotificationMessage);
		this.eventSource?.removeEventListener("error", this.onNotificationError);
		this.eventSource?.close();
		this.eventSource = undefined;
		this.opened = false;
	}

	isOpened() : boolean {
		return this.opened;
	}

	onNotificationMessage = (e : any) => {
		console.log("NOTIFICATION RECEIVED: %o", e);
		PubSub.publish(PUBSUB_SSE_EVENTS_TOPIC, JSON.parse(e.data) as SystemNotification);
	}

	onNotificationError = () => {
		this.eventSource?.close();
		this.eventSource = undefined;
		setTimeout(this.reconnect, 10000);
	}

	reconnect = () => {
		if (this.isOpened()) {
			this.open();
		}
	}
}