import {ITableEntity} from "../commons/components/table/DataTableComponent";

export class Variable implements IVariable{
    name : string;
    value : any;

    constructor(data? : any) {
        this.name = data.name;
        this.value = data.value;
    }
}

export class PatchVariables {
    updatedVariables : { [key: string]: any };

    constructor(data : { [key: string]: any }) {
        this.updatedVariables = data;
    }
}

export interface IVariable extends ITableEntity {
    name: string;
    value: any;
}

