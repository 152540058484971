import React from 'react';
import { buildGatewayUrl } from '../client/xhr';
import { Modal } from 'react-bootstrap';
import { FaAngleRight } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import "./auth-method-picker.css";
import Loading from "../commons/Loading";
import authenticationService from './AuthenticationService';
import { AuthMethod } from './Domain';

interface State {
  	methods? : AuthMethod[];
	errorGathering : boolean;
	errorAuthentication : boolean;
}

interface Props {
    params: any;
}

class AuthMethodPickerClass extends React.Component<Props, State> {
    params:any;

	constructor(props: Props) {
		super(props);
		this.state = {
			errorGathering: false,
			errorAuthentication: false,
			methods: undefined
		};
        this.params = this.props.params;
	}

	componentDidMount() {
        if (this.params["_authfailed"] && this.params["_authfailed"] === "true") {
			this.setState({errorAuthentication: true});
		} else {
			this.startAuthentication();
		}
	}

	startAuthentication() {
		// gather available authentication methods
		authenticationService.getMethods()
		.then(methods => {
			if (methods.length) {
				if (methods.length === 1) {
					// in case there is one method just click on it immediately (do not show the picker)
					this.methodClick(methods[0].pathName);
				} else {
					this.setState({methods});
				}
			} else {
				this.setState({errorGathering: true});
			}
		})
		.catch(error => {
			console.log(error);
			this.setState({errorGathering: true});
		});
	}

	methodsList = () => {
		let methodsList = this.state.methods!.map(m => this.createLink(m));
		return <>
			<h6>Choose desired authentication method:</h6>
			<ul className="list-group">{methodsList}</ul>
			</>;
	}

	createLink = (m : any) => {
		let image = `/images/authentication/${m.name}.svg`;
		return <li className="list-group-item list-group-item-action zoom"
					onClick={() => this.methodClick(m.pathName)}
					key={m.name}>
					<img alt={m.name} src={image} height="32" width="38" style={{marginRight: "1rem"}}/><strong>{m.name}</strong>
					<FaAngleRight size="32" style={{float: "right"}} />
				</li>;
	}

	methodClick = (pathName : string) => {
		this.setState({methods: undefined},() => {
			let params = {originalURL: window.location.href};
			let url = `/authentication/${pathName}/start?${new URLSearchParams(params).toString()}`;
			window.location.replace(buildGatewayUrl(url));
		});
	}

	render() {
		let content = (contentBody: any) => <>
						<Modal.Header>
							<Modal.Title>
								<img alt="" src="/images/logos/exite-dark.svg" height="32" className="d-none d-md-inline-block"/>
                    			<img alt="" src="/images/logos/exite-sm-dark.svg" height="32" className="d-inline-block d-md-none"/>
                    			&nbsp;WebEDI
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							{contentBody}
						</Modal.Body>
					</>;

		let loading = () => <>
						<Modal.Body>
							<div className="d-flex justify-content-center">
								<Loading />
							</div>
						</Modal.Body>
					</>;

		let errorGathering = <div className="d-flex justify-content-center">
							Unexpected error when gathering available authentication methods, please try again later.
						</div>;

		let errorAuthentication = <div className="d-flex justify-content-center">
							Unexpected error occurred when performing authentication, please try again later.
						</div>;

		return <Modal show={true} onHide={() => {}} animation={false} centered>
				{!!this.state.methods ? content(this.methodsList()) :
					this.state.errorGathering ? content(errorGathering) :
						this.state.errorAuthentication ?  content(errorAuthentication) :
							loading()}
				</Modal>;
	}
}
export default function AuthMethodPicker() {
    const params = useParams();
    return <AuthMethodPickerClass params={params}/>;
}
