import { Expression } from "./CommonTypes";
import { Language, getDecimalSeparatorForLanguage } from "../i18n/i18n";
import lodash from "lodash";

export default class CommonUtils {

	static randomString() : string {
		return Math.random().toString(36).substring(7);
	}

	static firstDefined(...args: any[]) : any {
		return args.find(a => a !== undefined);
	}

	static isDefined(input? : any) : boolean {
		return input !== undefined;
	}

	static requireDefined<T>(input? : T) : T {
		if (input === undefined) {
			throw Error("Undefined found where not wanted.");
		}
		return input;
	}

    static requireAtLeastOneDefined(...args : any[]) {
        if(!this.firstDefined(args)) {
            throw Error("At least one must be undefined");
        }
    }

	static parseBoolean(str : string) {
		return str === 'true';
	}

	static parseExpression(str : string, defaultType : "none" | "static" | "dynamic" = "none") : Expression | undefined {
		if (str === undefined) {
			return undefined;
		}
		return str && str.startsWith(">") ?
      		(str.startsWith(">>") ? { type: "dynamic", value: str.substring(2)} : { type: "static", value: str.substring(1)}) :
      		{ type: defaultType, value: str };
	}

	static escapeRegex(string : string) : string {
		return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
	}

	static formatStringNumber = (numberString : string, decimals : number, language: Language = "en") : string => {
		if (numberString === "") {
			return numberString;
		}
		let retval = numberString;
		retval = retval.replace(/\.0*$/, "");
		if (retval.indexOf(".") > -1) {
			retval = retval.replace(/0*$/, "");
			const zeros = decimals - numberString.substring(numberString.indexOf(".") + 1).length;
			if (zeros > 0) {
				retval = retval + "0".repeat(zeros);
			} else if (zeros < 0) {
				throw new Error(`Can not format number ${numberString} to ${decimals} decimals without loosing precision.`);
			}
			retval = retval.replace(".", getDecimalSeparatorForLanguage(language));
		} else {
			retval = retval + getDecimalSeparatorForLanguage(language) + "0".repeat(decimals);
		}
		retval = retval.replace(/^0*$/, "");
		if (retval.startsWith(".") || retval === "") {
			retval = "0" + retval;
		}
		return retval;
	}

	static generatePseudoRandomString = (): string => {
        return Date.now().toString(36) + Math.random();
    }

	static setsEqual = (setA : any[], setB : any[]) : boolean => {
		if (setA.length !== setB.length) return false;
		return setA.filter(e => setB.indexOf(e) > -1).length === setA.length;
	}

	static hasLeadingOrTrailingWhitespaces = (str : string) : boolean => {
		return /^\s.* | .*\s$/.test(str);
	}

	static trimObjectFields = (updates:any):any => {
		let str = JSON.stringify(updates).replace(/"\s+|\s+"/g,'"').replace(/:""/g, ":null");
		return str === "" ? null: JSON.parse(str);
	}

	static createRange = (max : number) : number[] => {
		return [...Array(max).keys()];
	}

	static removeBlanks = (text : string) => {
		return text.replaceAll(/\s+/g, "");
	}

    static firstOrError = (data: any) => {
        if(data && data.length > 0) {
            return data[0];
        }
        throw new Error("Not found")
    }

	static compareValueNotEq = (a:any, b:any):boolean => {
		if (typeof a !== typeof b) return true;
		if (typeof a === 'object') {
			return !lodash.isEqual(a, b);
        }
		return a !== b;
	}

	static parseLiteralType<T>(input: string, literals: readonly T[]): T {
		const value = literals.find(literal => input === literal);
		if (value) {
			return value;
		}
		throw new Error(`Value '${input}' is not allowed here, allowed values: ${literals}`);
	}
}