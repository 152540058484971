import {ComponentType, lazy} from "react";

const LOADER_ATTEMPTS = 5;
const RELOAD_TIMEOUT = 1000;

function componentLoader(lazyComponent: () => Promise<any>, attemptsLeft:number): Promise<{ default: ComponentType<any>; }>  {
    return new Promise((resolve, reject) => {
        lazyComponent()
            .then(resolve)
            .catch((error:Error) => {
                setTimeout(() => {
                    if (attemptsLeft === 1) {
                        reject(error);
                        return;
                    }
                    componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
                }, RELOAD_TIMEOUT);
            });
    });
}

export const lazyLoadWithRetry = (importer: () => Promise<any>) => {
    return lazy(() => componentLoader(importer, LOADER_ATTEMPTS));
}
