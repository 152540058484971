import React from 'react';
import i18n, { Language } from '../../i18n/i18n';
import { FlagIconCode } from "react-flag-kit";
import { NavDropdown } from 'react-bootstrap';
import lodash from 'lodash';
import "./LanguageMenu.css";

export default function LanguageMenu() {

    const defaultLang: string = "en";

    const flagMap: { [key: Language]: FlagIconCode; } = {
        "en": "GB",
        "bg": "BG",
        "de": "AT",
        "cs": "CZ",
        "hr": "HR",
        "hu": "HU"
    };

    const activeLanguage = (): string => {
        return i18n.languages[0] || defaultLang
    }
    const supportedLanguageList = (): string[] => {
        return Object.keys(flagMap)
    }
    const otherLanguageList = (): string[] => {
        return supportedLanguageList().filter((lang: string) => lang !== activeLanguage())
    }

    const getFlagIcon = (lng: string): FlagIconCode => {
        return lodash.get(flagMap, lng, "GB");
    }

    const changeLanguage = (lng: string) => {
        console.log("switching to " + lng);
        i18n.changeLanguage(lng);
    }

    const renderFlag = (country: string): JSX.Element => {
        return <img className="flag" alt={country} src={`https://cdn.jsdelivr.net/gh/umidbekk/react-flag-kit@1/assets/${country}.svg`} />
    }

    return <NavDropdown className="flagdropdown" title={renderFlag(getFlagIcon(activeLanguage()))} id="country-nav-dropdown" >
        {otherLanguageList().map((lang: string) => <NavDropdown.Item key={lang} onClick={() => { changeLanguage(lang) }}>
            {renderFlag(getFlagIcon(lang))}
        </NavDropdown.Item>)}
    </NavDropdown>
}
