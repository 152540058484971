
export class AuthenticatedUser {
	id : string;
	accountId: string;
	permissions: number;
	authorized : boolean;
	authName : string;
	authMethod : string;
    tenantId: string;
    organization: string;

	constructor(data? : any) {
		this.id = data.id;
		this.accountId = data.accountId;
		this.permissions = data.roles && data.roles.length > 0 ? data.roles.reduce((a:number, b:number) => a && b) : 0;
		this.authorized = data.authorized;
		this.authName = data.authName;
		this.authMethod = data.authMethod;
        this.tenantId = data.tenantId;
        this.organization = data.organization;
	}

	hasPermissions = (requiredPermissions : number) : boolean => {
		return (this.permissions & requiredPermissions) === requiredPermissions;
	}
}

export class SessionInfo {
	
	startedAt: string;
	expiresAt: string;
	secondsRemain: number;

	constructor(data? : any) {
		this.startedAt = data.startedAt;
		this.expiresAt = data.expiresAt;
		this.secondsRemain = data.secondsRemain;
	}
}

export class AuthenticationSession {
	user : AuthenticatedUser;
	sessionInfo: SessionInfo;

	constructor(data? : any) {
		this.user = new AuthenticatedUser(data.user);
		this.sessionInfo = new SessionInfo(data.sessionInfo);
	}
}

export interface AuthMethod {
	pathName: string;
}