import { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";

type RequestHandler = {request: InternalAxiosRequestConfig<any>, resolver: (value: InternalAxiosRequestConfig<any>) => void};

/**
 * Concurrency manager wraps axios by request concurrency limiting logic.
 * Idea was taken over from https://github.com/bernawil/axios-concurrency
 *
 * @param axios
 * @param DEFAULT_CONCURRENCY
 * @returns
 */
export class ConcurrencyManager {

    readonly DEFAULT_CONCURRENCY;

    private concurrency!: number;
    private waiting: RequestHandler[] = [];
    private running: RequestHandler[] = [];
    private interceptors : {request? : number, response? : number} = {};

    constructor(axios: AxiosInstance, concurrency: number = 1000) {
        this.DEFAULT_CONCURRENCY = concurrency;
        this.setConcurrency(concurrency);

        // queue concurrent requests
        this.interceptors.request = axios.interceptors.request.use(
            this.requestHandler
        );
        this.interceptors.response = axios.interceptors.response.use(
            this.responseHandler,
            this.responseErrorHandler,
        );
    }

    setConcurrency = (value : number) => {
        if (value < 1)
            throw new Error("Concurrency Manager Error: minimum concurrent requests is 1");
        this.concurrency = value;
    }

    resetConcurrency = () => {
        this.concurrency = this.DEFAULT_CONCURRENCY;
    }

    startWaiting = () => {
        while (this.running.length < this.concurrency && this.waiting.length) {
            const queued = this.waiting.shift();
            queued!.resolver(queued!.request);
            this.running.push(queued!);
        }
    }

    requestHandler = (req: InternalAxiosRequestConfig<any>) : Promise<InternalAxiosRequestConfig<any>> => {
        return new Promise(resolve => {
            this.waiting.push({ request: req, resolver: resolve });
            setTimeout(this.startWaiting, 0);
        });
    }

    // Use as interceptor. Execute queued request upon receiving a response
    responseHandler = (res: AxiosResponse<any, any>) => {
        this.running.shift();
        this.startWaiting();
        return res;
    }

    responseErrorHandler = (res: any) => {
        return Promise.reject(this.responseHandler(res));
    }
}

export const register = (axios : AxiosInstance) => {
    return new ConcurrencyManager(axios);
}