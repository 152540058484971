import axios, { AxiosInstance } from 'axios';
import { STORAGE_TOKEN_NAME } from '../commons/CommonConstants';
import { ConcurrencyManager, register } from './AxiosConcurrencyManager';
import urlJoin from 'url-join';

const fileSettings = (window as any).settings || {};
const gatewayUrl = !!fileSettings.gatewayUrl ? fileSettings.gatewayUrl : process.env.REACT_APP_BACKEND_URL;
const axiosTimeout : number = parseInt(!!fileSettings.axiosTimeout ? fileSettings.axiosTimeout : process.env.REACT_APP_AXIOS_TIMEOUT);

export const buildGatewayUrl = (relativePath: string): string => urlJoin(gatewayUrl, relativePath);

const xhr : AxiosInstance = axios.create({
	baseURL: gatewayUrl,
	withCredentials: true,
	timeout: axiosTimeout,
});

xhr.interceptors.request.use(request => {
    if (localStorage.getItem(STORAGE_TOKEN_NAME)) {
        console.log("Setting Authorization header");
        request.headers["Authorization"] = `Bearer ${localStorage.getItem("WEBEDI_AUTH")}`;
    }
    return request;
});

xhr.interceptors.response.use(response => {
    if (response.headers["authorization"] && response.headers["authorization"].startsWith("Bearer ")) {
        localStorage.setItem(STORAGE_TOKEN_NAME, response.headers["authorization"].substring("Bearer ".length))
    }
    return response;
});

export const concurrencyManager : ConcurrencyManager = register(xhr);

export default xhr;
