import React from "react";
import './forecast.css';
import {addDays, format, parse} from "date-fns";
import lodash from "lodash";

const WEEK_DATE_PATTERN = "yI";
const DATE_PATTERN = "yyyy-MM-dd";
const SHORT_DATE_PATTERN = "dd.MM";

interface IForecast {
    quantity: number;
    quantity_unit: string;
    delivery_start_date: string;
    delivery_end_date: string;
    created: string;
    commitment_delivery_code: string;
    frequency: string;
    id: string;
}

interface ICumulatives {
    quantity_received: number,
    quantity_ordered: number,
    quantity_shipped: number
}

interface IItem {
    article_number_buyer: string;
    article_number?: string;
    article_ean?: string;
    created: string;
    cumulatives: ICumulatives,
    id: string;
    kanban_number: string;
    forecasts?: IForecast[];
}

interface IConsignee {
    party_name_1: string;
    party_id: string;
    contact?: string;
    created: string;
    party_qualifier?: string;
    id: string;
    street_1?: string;
    final_destination?: string;
    transhipment_place?: string;
    place?: string;
    postcode?: string;
    country_code?: string;
    dock_code: string;
    warehouse_code: string;
    items: IItem[];
}

const givenData: { "consignees": IConsignee[] } = {
    "consignees": [
        {
            "party_name_1": "TOYOTA MOTOR UK",
            "party_id": "UNKNOWN",
            "id": "66d0577def5542e4cde5d2a9",
            "dock_code": "A1",
            "warehouse_code": "0048",
            "contact": "MARK RATCLIFFE",
            "final_destination": "3E",
            "transhipment_place": "",
            "created": "2024-08-29T11:11:57.829Z",
            "items": [
                {
                    "article_number_buyer": "6294002121BY",
                    "article_number": "6294002121SU",
                    "article_ean": "",
                    "kanban_number": "145T",
                    "created": "2024-08-29T11:11:57.846Z",
                    "cumulatives": {
                        "quantity_received": 1500,
                        "quantity_ordered": 2000,
                        "quantity_shipped": 1800
                    },
                    "id": "66d0577def5542e4cde5d2ab",
                    "forecasts": [
                        {
                            "quantity": 100,
                            "quantity_unit": "PCE",
                            "delivery_start_date": "2023-10-20T00:00:00.000Z",
                            "delivery_end_date": "2023-10-20T00:00:00.000Z",
                            "id": "66d0577def5542e4cde5d2c1",
                            "commitment_delivery_code": "1",
                            "frequency": "Y",
                            "created": "2024-08-29T11:11:57.891Z"
                        },
                        {
                            "quantity": 120,
                            "quantity_unit": "PCE",
                            "delivery_start_date": "2023-10-16T00:00:00.000Z",
                            "delivery_end_date": "2023-10-16T00:00:00.000Z",
                            "id": "66d0577def5542e4cde5d2bd",
                            "commitment_delivery_code": "1",
                            "frequency": "Y",
                            "created": "2024-08-29T11:11:57.882Z"
                        },
                        {
                            "quantity": 110,
                            "quantity_unit": "PCE",
                            "delivery_start_date": "2023-10-17T00:00:00.000Z",
                            "delivery_end_date": "2023-10-17T00:00:00.000Z",
                            "id": "66d0577def5542e4cde5d2be",
                            "commitment_delivery_code": "1",
                            "frequency": "Y",
                            "created": "2024-08-29T11:11:57.884Z"
                        },
                        {
                            "quantity": 130,
                            "quantity_unit": "PCE",
                            "delivery_start_date": "2023-10-18T00:00:00.000Z",
                            "delivery_end_date": "2023-10-18T00:00:00.000Z",
                            "id": "66d0577def5542e4cde5d2bf",
                            "commitment_delivery_code": "1",
                            "frequency": "Y",
                            "created": "2024-08-29T11:11:57.886Z"
                        },
                        {
                            "quantity": 130,
                            "quantity_unit": "PCE",
                            "delivery_start_date": "2023-10-19T00:00:00.000Z",
                            "delivery_end_date": "2023-10-19T00:00:00.000Z",
                            "id": "66d0577def5542e4cde5d2c0",
                            "commitment_delivery_code": "1",
                            "frequency": "Y",
                            "created": "2024-08-29T11:11:57.888Z"
                        },
                        {
                            "quantity": 550,
                            "quantity_unit": "PCE",
                            "delivery_start_date": "2023-11-13T00:00:00.000Z",
                            "delivery_end_date": "2023-11-19T00:00:00.000Z",
                            "id": "66d0577def5542e4cde5d2b4",
                            "commitment_delivery_code": "4",
                            "frequency": "W",
                            "created": "2024-08-29T11:11:57.871Z"
                        },
                        {
                            "quantity": 510,
                            "quantity_unit": "PCE",
                            "delivery_start_date": "2023-11-20T00:00:00.000Z",
                            "delivery_end_date": "2023-11-26T00:00:00.000Z",
                            "id": "66d0577def5542e4cde5d2b5",
                            "commitment_delivery_code": "4",
                            "frequency": "W",
                            "created": "2024-08-29T11:11:57.874Z"
                        },
                        {
                            "quantity": 540,
                            "quantity_unit": "PCE",
                            "delivery_start_date": "2023-11-27T00:00:00.000Z",
                            "delivery_end_date": "2023-12-03T00:00:00.000Z",
                            "id": "66d0577def5542e4cde5d2b6",
                            "commitment_delivery_code": "4",
                            "frequency": "W",
                            "created": "2024-08-29T11:11:57.876Z"
                        },
                        {
                            "quantity": 590,
                            "quantity_unit": "PCE",
                            "delivery_start_date": "2023-10-30T00:00:00.000Z",
                            "delivery_end_date": "2023-11-05T00:00:00.000Z",
                            "id": "66d0577def5542e4cde5d2b2",
                            "commitment_delivery_code": "4",
                            "frequency": "W",
                            "created": "2024-08-29T11:11:57.867Z"
                        },
                        {
                            "quantity": 580,
                            "quantity_unit": "PCE",
                            "delivery_start_date": "2023-10-23T00:00:00.000Z",
                            "delivery_end_date": "2023-10-29T00:00:00.000Z",
                            "id": "66d0577def5542e4cde5d2b1",
                            "commitment_delivery_code": "4",
                            "frequency": "W",
                            "created": "2024-08-29T11:11:57.865Z"
                        },
                        {
                            "quantity": 600,
                            "quantity_unit": "PCE",
                            "delivery_start_date": "2023-11-06T00:00:00.000Z",
                            "delivery_end_date": "2023-11-12T00:00:00.000Z",
                            "id": "66d0577def5542e4cde5d2b3",
                            "commitment_delivery_code": "4",
                            "frequency": "W",
                            "created": "2024-08-29T11:11:57.869Z"
                        }
                    ]
                }
            ]
        }
    ]
};
const Forecast = () => {
    const getFirstDayOfWeek = (date: string): Date => {
        const weekNumber = date.slice(-2);
        const year = date.slice(0, 4);
        return parse(weekNumber.toString(), "I", new Date().setFullYear(+year));
    }


    const prepareData = (consignees: IConsignee[]) => {

        const result: {
            consignee: IConsignee,
            item: IItem,
            weeks: { [date: string]: number },
            forecasts: { [date: string]: {quantity:number, code:number} }
        }[] = [];
        consignees.forEach((consignee: IConsignee) => {

            consignee.items.forEach((item) => {
                const weeks = {};
                const forecasts = {};
                item.forecasts?.forEach((forecast) => {
                    const isDate = forecast.delivery_start_date.includes("-");
                    try {
                        const date = !isDate ? getFirstDayOfWeek(forecast.delivery_start_date) : new Date(forecast.delivery_start_date) ;//parse(forecast.delivery_start_date, DATE_PATTERN, new Date());
                        lodash.set(weeks, format(date, WEEK_DATE_PATTERN), 1);
                        lodash.set(forecasts, format(date, DATE_PATTERN), {quantity:+forecast.quantity, code: +forecast.commitment_delivery_code});
                    } catch (e) {
                        console.log(forecast.delivery_start_date, e);
                    }
                });
               if (Object.keys(forecasts).length > 0) {
                    result.push({consignee, item, weeks, forecasts});
                }
            })
        });
        return result;
    }


    const getWeekDays = (dateStr: string): Date[] => {
        const result = [];
        let date = getFirstDayOfWeek(dateStr);
        while (result.length < 7) {
            result.push(date);
            date = addDays(date, 1)
        }
        return result;

    }
    const preparedData = prepareData(givenData.consignees);
    const weeks = lodash.uniq([...preparedData.flatMap(datum => Object.keys(datum.weeks))]);

    return <div className={"forecast"}>
        <table className="table table-bordered align-middle">
            <thead>
            <tr className={"text-center align-top"}>
                <th rowSpan={2} className={"text-center"}>No</th>
                <th rowSpan={2} className={"text-center"}>Kanban No</th>
                <th rowSpan={2} style={{minWidth: "200px"}}>Part No</th>
                <th rowSpan={2}>Container Style</th>
                <th rowSpan={2} className={"text-center"}>QPC (Loc.)</th>
                <th rowSpan={2}>Unit class</th>
                {weeks.map(week =>
                    <th colSpan={7} key={'week' + week}>{week}</th>
                )}
            </tr>
            <tr className={"small"}>
                {weeks.map(week => {
                    const days = getWeekDays(week);
                    return days.map(day => <td key={"date" + day.toTimeString()}>{format(day, SHORT_DATE_PATTERN)}</td>)

                })}

            </tr>
            </thead>
            <tbody>
            {preparedData.map((rowInfo, idx) => {
                return <tr key={rowInfo.item.id}>
                    <td>{idx + 1}</td>
                    <td>{rowInfo.item.kanban_number}</td>
                    <td>{rowInfo.item.article_number_buyer}</td>
                    <td>{rowInfo.consignee.dock_code}</td>
                    <td>{rowInfo.item.article_ean}</td>
                    <td>{rowInfo.item.forecasts![0]?.quantity_unit}</td>
                    {weeks.map(week => {
                        const days = getWeekDays(week);
                        return days.map(day =>
                        {   const forecastData = lodash.get(rowInfo.forecasts, format(day, DATE_PATTERN));
                            return <td className={"vertical-value " + (forecastData?.code ? "forecast-code-" +forecastData?.code : "")}
                                key={"item" + rowInfo.item.id + "date" + day.toTimeString()}>
                                {forecastData?.quantity ?? 0}</td>})
                    })}

                </tr>;
            })}
            </tbody>
        </table>
    </div>
}
export default Forecast;
