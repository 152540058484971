import React from 'react'
import i18n from "i18next";
import { initReactI18next, WithTranslation, withTranslation } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './translations_en.json';
import translationDE from './translations_de.json';
import translationCZ from './translations_cs.json';
import translationHR from './translations_hr.json';
import translationHU from './translations_hu.json';
import translationBG from './translations_bg.json';
import dateAndTime from 'date-and-time';

// the translations
const RESOURCES = {
  en: {
    translation: translationEN
  },
  de: {
    translation: translationDE
  },
  cs: {
    translation: translationCZ
  },
  hr: {
    translation: translationHR
  },
  hu: {
    translation: translationHU
  },
  bg: {
    translation: translationBG
  }
};

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}

export const SUPPORTED_LANGUAGES = Object.keys(RESOURCES);
export type Language = (typeof SUPPORTED_LANGUAGES)[number];

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: RESOURCES,
    debug: true,
    load: "languageOnly",
    supportedLngs: SUPPORTED_LANGUAGES,
    fallbackLng: "en",
    keySeparator: ".",
    returnNull: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: function(value, format, lng) {
          if(value instanceof Date) {
            console.log("I18next - custom format of date value: " + value + " --> " + format);
            if (!isNaN(value.getTime())) {
              return dateAndTime.format(value,format ? format : "");
            }
            return "";
          }
          return value;
      }
    }
  });

export const getDecimalSeparatorForLanguage = (language:string) => {
  switch(language) {
    case "en":
      return ".";
    default: // we assume most of languages we support will have , as a decimal separator
      return ",";
  }
}

export const getDecimalSeparator = () => {
  return getDecimalSeparatorForLanguage(i18n.languages[0]);
}

class LanguageChangeNotificationComponent extends React.Component<{onLanguageChange: () => void} & WithTranslation> {
  componentDidUpdate(prevProps: any) {
    if (this.props.t !== prevProps.t) {
      this.props.onLanguageChange();
    }
  }
  render() {
    return null;
  }
}

const LanguageChangeNotifier = withTranslation() (LanguageChangeNotificationComponent);
export { LanguageChangeNotifier }

export default i18n;
