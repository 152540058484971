import xhr from "../client/xhr";
import {
    FlowTemplate,
    FlowTemplatePatchRequest,
    FlowTemplateCreateRequest,
    DocumentTemplate,
    DocumentTemplateCreateRequest,
    DocumentTemplatePatchRequest,
    DocumentTemplateMinimal,
    ITemplateGroup,
    FlowTemplateGroupCreateRequest,
    FlowTemplateGroupPatchRequest,
    ITemplateGroupWithTemplates,
    DocumentTemplateVersionPatchRequest,
    PartnerGln, FlowStepTemplate
} from "./Domain";
import CommonUtils from "../commons/CommonUtils";

class TemplatesService {

    private static instance: TemplatesService;

	public static getInstance(): TemplatesService {
        if (!TemplatesService.instance) {
            TemplatesService.instance = new TemplatesService();
        }
        return TemplatesService.instance;
    }

    public listLatestFlowTemplates(organization?: string) : Promise<FlowTemplate[]> {
        return xhr.get('/gateway/templates/flow-templates?_sortBy=templateName&_latestOnly=true' + (organization ? "&organization=" + organization : ""))
            .then((response : any) => (response.data as any[]).map(d => new FlowTemplate(d)));
    }

    public listLatestFlowTemplatesByTemplateGroup(groupId: string) : Promise<FlowTemplate[]> {
        return xhr.get(`/gateway/templates/flow-templates?_sortBy=templateName&_latestOnly=true&flowTemplateGroupId=${groupId}`)
            .then((response : any) => response.data);
    }

    public listLatestCreatableFlowTemplates(groupAccountId: string) : Promise<FlowTemplate[]> {
        return xhr.get(`/gateway/templates/flow-templates?stepType=CREATE&stepIndex=0&groupAccountId=${groupAccountId}&_sortBy=templateName&_latestOnly=true`)
            .then((response : any) => (response.data as any[]).map(d => new FlowTemplate(d)));
    }

    public listLatestFlowTemplatesForAccount(groupAccountId: string) : Promise<FlowTemplate[]> {
        return xhr.get(`/gateway/templates/flow-templates?groupAccountId=${groupAccountId}&_sortBy=templateName&_latestOnly=true`)
            .then((response : any) => (response.data as any[]).map(d => new FlowTemplate(d)));
    }

    public getFlowTemplate(id: string) : Promise<FlowTemplate> {
        return xhr.get(`/gateway/templates/flow-templates?id=${id}`)
            .then((response : any) => new FlowTemplate(CommonUtils.firstOrError(response.data)));
    }

    public updateFlowTemplate(templateName: string, organization: string, sourceFlowTemplate: string, request: FlowTemplatePatchRequest) : Promise<FlowTemplate> {
        return xhr.patch(`/gateway/templates/organization/${organization}/flow-templates/${templateName}?sourceFlowTemplate=${sourceFlowTemplate}`, CommonUtils.trimObjectFields(request))
            .then((response : any) => new FlowTemplate(response.data));
    }

    public listFlowTemplatesNames = async () : Promise<string[]> => {
        const response = await xhr.get('/gateway/templates/flow-templates', { params: { "_latestOnly": "true", "_include": "templateName", "_sortBy": "templateName" } });
        return (response.data as {templateName : string}[]).map(template => template.templateName);
    }

    public listLatestCreatableFlowTemplateNamesWithGln = async (groupAccountId:string) : Promise<{ flowTemplate: string, gln: PartnerGln}[]> => {
        const response = await xhr.get('/gateway/templates/flow-templates', {
            params:
                {
                    "_latestOnly": "true",
                    "stepType": "CREATE",
                    "stepIndex": "0",
                    "groupAccountId": groupAccountId,
                    "_include": "templateName,partnerGln,steps",
                    "_sortBy": "templateName"
                }
        });
        return (response.data as {templateName : string, partnerGln: object, steps:FlowStepTemplate[]}[])
            .filter(o => o.steps[0].options[0].option !== "ACCUMULATE")
            .map(template => {return {flowTemplate: template.templateName, gln: new PartnerGln(template.partnerGln)}});
    }

    public listDocumentTemplateNames = () : Promise<{name: string, organization: string, state: string}[]> => {
        return xhr.get('/gateway/templates/document-templates?templateVersion=latest&_sortBy=templateName&_include=id,templateName,templateVersion,organization,state')
            .then((response : any) => (response.data as any[]).map(template => ({name: template.templateName, organization: template.organization, state:template.state})));
    }

    public createFlowTemplate(request: FlowTemplateCreateRequest, organization: string) : Promise<FlowTemplate> {
        return xhr.post(`/gateway/templates/organization/${organization}/flow-templates`, CommonUtils.trimObjectFields(request))
            .then((response : any) => new FlowTemplate(response.data));
    }

    public listLatestDocumentTemplatesMinimal() : Promise<DocumentTemplateMinimal[]> {
        return xhr.get('/gateway/templates/document-templates?_sortBy=templateName&templateVersion=latest&_include=id,templateName,templateVersion,organization,state')
            .then((response : any) => response.data);
    }

    public listDocumentTemplatesByNameRaw(name:string) : Promise<DocumentTemplate[]> {
        return xhr.get('/gateway/templates/document-templates?_sortBy=templateName&templateName='+name+'&raw=true')
            .then((response : any) => response.data);
    }

    public getDocumentTemplate(id: string) : Promise<DocumentTemplate> {
        return xhr.get(`/gateway/templates/document-templates?id=${id}`)
            .then((response : any) => CommonUtils.firstOrError(response.data));
    }

    public getDocumentTemplateRaw(id: string) : Promise<DocumentTemplate> {
        return xhr.get(`/gateway/templates/document-templates?id=${id}&raw=true`)
            .then((response : any) => CommonUtils.firstOrError(response.data));
    }

    public createDocumentTemplate(request: DocumentTemplateCreateRequest, organization:string ) : Promise<DocumentTemplate> {
        return xhr.post(`/gateway/templates/organization/${organization}/document-templates`, request)
            .then((response : any) => response.data);
    }

    public updateDocumentTemplate(request: DocumentTemplatePatchRequest, organization: string ,templateName: string) : Promise<DocumentTemplate> {
        return xhr.patch( `/gateway/templates/organization/${organization}/document-templates/${templateName}`, request)
            .then((response : any) => response.data);
    }

    public updateDocumentTemplateVersion(request: DocumentTemplateVersionPatchRequest, organization: string ,templateName: string, templateVersion: number) : Promise<DocumentTemplate> {
        return xhr.patch( `/gateway/templates/organization/${organization}/document-templates/${templateName}/versions/${templateVersion}`, request)
            .then((response : any) => new DocumentTemplate(response.data));
    }

    public getStepsInGroup() : Promise<{[stepName : string]: string[]}> {
        return xhr.get(`/gateway/templates/flow-template-groups/steps-names`)
            .then((response : any) => response.data);
    }

    public getGroupOfAccount(accountId: string) : Promise<ITemplateGroup> {
        return xhr.get(`/gateway/templates/flow-template-groups?accountId=${accountId}&_includeFlowTemplates=false`)
            .then((response : any) => response.data ? response.data[0] : response.data);
    }

    public listFlowTemplatesGroups() : Promise<ITemplateGroup[]> {
        return xhr.get('/gateway/templates/flow-template-groups?_includeFlowTemplates=false')
            .then((response : any) => response.data);
    }

    public listFlowTemplatesGroupsWithTemplateNames() : Promise<ITemplateGroupWithTemplates[]> {
        return xhr.get('/gateway/templates/flow-template-groups?_includeFlowTemplates=true')
            .then((response : any) => response.data);
    }

    public getFlowTemplatesGroup(id: string, organizationName: string) : Promise<ITemplateGroup> {
        return xhr.get(`/gateway/templates/organization/${organizationName}/flow-template-groups/${id}`)
            .then((response : any) => response.data);
    }

    public createFlowTemplateGroup(request: FlowTemplateGroupCreateRequest, organizationName: string) : Promise<ITemplateGroup> {
        return xhr.post(`/gateway/templates/organization/${organizationName}/flow-template-groups`, CommonUtils.trimObjectFields(request))
            .then((response : any) => response.data);
    }

    public patchFlowTemplateGroup(request: FlowTemplateGroupPatchRequest, id: string, organizationName: string) : Promise<ITemplateGroup> {
        return xhr.patch(`/gateway/templates/organization/${organizationName}/flow-template-groups/${id}`, CommonUtils.trimObjectFields(request))
            .then((response : any) => response.data);
    }

    public deleteFlowTemplateGroup(id: string, organizationName: string) : Promise<void> {
        return xhr.delete(`/gateway/templates/organization/${organizationName}/flow-template-groups/${id}`);
    }

}

const templatesService = TemplatesService.getInstance();
export default templatesService;
