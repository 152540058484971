import 'react-app-polyfill/ie11';
import "react-app-polyfill/stable";
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import './i18n/i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

// configure logging (hide for production)
if ((window as any).settings?.disableLogging === true) {
	console.log("Setting off debugging.")
	console.log = function () {};
}

const container = document.getElementById('root');
const root = createRoot(container!);
const router = createBrowserRouter([
    { path: "*", Component: App },
  ]);

//root.render(<BrowserRouter><App /></BrowserRouter>);
root.render(<RouterProvider router={router} />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
