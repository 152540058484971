import {Vat} from "../product/Domain";
import {ITableEntity} from "../commons/components/table/DataTableComponent";
import {IVariable} from "../masterdata/Domain";
import {IInvoicingAccountInfoData} from "../company/CompanyPage";

export const DEFAULT_TIME_ZONE = "Europe/Vienna";

export interface IAccount {
    id: string;
    name: string;
    gln: string;
    email:string;
    language:string;
    timezone:string;
    lockingTimeout:number;
    organicCertificationNumber:string;
    tenantId: string;
    organization: string;
    defaultTaxRate?: Vat | null;
    defaultCurrency?: string | null;
    footers?: {[id: string]: string};
    addresses?:Array<IEditelAddress>;
    publicId?:string;
    invoicingAccountInformation?:IInvoicingAccountInfoData;
}
export interface IKeyValue {
    key: string;
    value: any;
}

export interface AccountVariable extends IVariable{}

export interface IEditelAddress {
    id:string;
    gln:string;
    name:string;
    street:string;
    zip:string;
    town:string;
    country:string;
    email:string;
    phone:string;
    taxId:string;
    locationType:string;
    public:boolean;
}

export class AccountWithUsers implements ITableEntity{
    id: string;
    name: string;
    gln: string;
    email:string;
    language:string;
    timezone:string;
    lockingTimeout:number;
    organicCertificationNumber:string;
    tenantId: string;
    organization: string;
    users: User[];
    authNames: string[];
    publicId : string;
    defaultTaxRate?: Vat | null;
    defaultCurrency?: string | null;

    constructor(data? : any) {
        this.id = data.id;
        this.name = data.name;
        this.gln = data.gln;
        this.email = data.email;
        this.language = data.language;
        this.timezone = data.timezone;
        this.lockingTimeout = data.lockingTimeout;
        this.organicCertificationNumber = data.organicCertificationNumber;
        this.tenantId = data.tenantId;
        this.organization = data.organization;
        this.defaultTaxRate = data.defaultTaxRate as Vat;
        this.defaultCurrency = data.defaultCurrency;
        this.users = data.users ? data.users.map((u: any) => new User(u)) : [];
        this.publicId = data.publicId;
        this.authNames = this.users.reduce((allAuthNames : string[], user) => {
            if(user.authName?.exite) {
                allAuthNames.push(user.authName?.exite);
            }
            if(user.authName?.spar) {
                allAuthNames.push(user.authName?.spar);
            }
            return allAuthNames;
        }, [])
    }

    static createFromIAccount(account:IAccount) {
        return new AccountWithUsers({
                id : account.id,
                name : account.name,
                gln : account.gln,
                email : account.email,
                language : account.language,
                timezone : account.timezone,
                lockingTimeout : account.lockingTimeout,
                organicCertificationNumber : account.organicCertificationNumber,
                tenantId : account.tenantId,
                organization: account.organization
            }
        )
    }

}

export class CreateAccountRequest {
    name: string;
    gln: string;
    language:string;
    timezone:string;
    tenantId?: string;
    organicCertificationNumber?:string;
    email?: string;
    primaryAddress:IEditelAddress;

    constructor(name: string, primaryAddress: IEditelAddress, gln: string, language: string, timezone: string, tenantId?: string, email?: string, organicCertificationNumber?: string) {
        this.name = name;
        this.gln = gln;
        this.language = language;
        this.timezone = timezone;
        this.tenantId = tenantId;
        this.organicCertificationNumber = organicCertificationNumber;
        this.email = email;
        this.primaryAddress = primaryAddress;
    }
}

export interface PartnerData {
    publicId: string;
    name: string;
}

export class User implements ITableEntity {
    id : string;
    firstName? : string;
    lastName? : string;
    lastLogin? : Date;
    accountId : string;
    roles: number[];
    authName?: AuthName;

    constructor(data? : any) {
        this.id = data.id;
        this.firstName = data.firstName ? data.firstName : undefined;
        this.lastName = data.lastName ? data.lastName : undefined;
        this.lastLogin = data.lastLoginTimestamp ? new Date(data.lastLoginTimestamp) : undefined;
        this.accountId = data.accountId;
        this.roles = data.roles;
        this.authName = data.authName ? new AuthName(data.authName) : undefined;
    }

    authNamesToString(): string {
        return [this.authName?.exite, this.authName?.spar].filter(val => val).map(val => "'" + val + "'").join(' / ')
    }

}

class AuthName {
    exite: string;
    spar: string;

    constructor(data? : any) {
        this.exite = data.exite;
        this.spar = data.spar;
    }
}

export class UserCreateRequest {
    authName: {exite?: string, spar?: string}
    firstName? : string;
    lastName? : string;
    roles: number[];

    constructor(data : any) {
        this.firstName = data.firstName;
        this.lastName = data.lastName
        this.authName = {exite: data.authNameExite, spar: data.authNameSpar}
        this.roles = data.roles;
    }

    authNamesToString(): string {
        return [this.authName?.exite, this.authName?.spar].filter((val) => val).map(val => "'" + val + "'").join(' / ')
    }
}

export class UserPatchRequest {
    firstName? : string;
    lastName? : string;
    roles?: number[];
    authNameExite? : string;
    authNameSpar? : string;

    constructor(firstName? : string, lastName? : string, roles?: number[], authNameExite? : string, authNameSpar? : string) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.roles = roles;
        this.authNameExite = authNameExite;
        this.authNameSpar = authNameSpar;
    }
}

export class UserModifications {
    add?: UserCreateRequest[];
    patch?: {[id: string]: UserPatchRequest};
    delete?: string[];

    constructor(add?: UserCreateRequest[], patch?: {[id: string]: UserPatchRequest}, del?: string[]) {
        this.add = add;
        this.patch = patch;
        this.delete = del;
    }
}

export class AccountWithUsersPatchRequest {
    gln?: string;
    tenantId?: string;
    language?: string;
    timezone?: string;
    userModifications?: UserModifications;

    constructor(gln?: string, tenantId?: string, language?: string, timezone?: string, userModifications?: UserModifications) {
        this.gln = gln;
        this.tenantId = tenantId;
        this.language = language;
        this.timezone = timezone;
        this.userModifications = userModifications;
    }
    static createCreateUserRequest(userModifications: UserModifications) {
        let request = new AccountWithUsersPatchRequest();
        request.userModifications = userModifications;
        return request;
    }
}

export class AccountWithUsersPatchResponse {
    accountResponse: IAccount;
    usersCreated: User[];
    usersPatched: User[];
    usersDeleted: User[];

    constructor(data : any) {
        this.accountResponse = data.accountResponse;
        this.usersCreated = data.usersCreated ? data.usersCreated.map((u: any) => new User(u)) : [];
        this.usersPatched = data.usersPatched ? data.usersPatched.map((u: any) => new User(u)) : [];
        this.usersDeleted = data.usersDeleted ? data.usersDeleted.map((u: any) => new User(u)) : [];
    }
}
