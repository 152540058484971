import xhr from "../../client/xhr";
import CacheMap from "./CacheMap";

export default class CacheUtils {
    public static retrieveObject<T>(
        id:string,
        loadingMap:Map<string,{waiting:Array<{resolve:any,reject:any}>}>,
        objectMap:CacheMap<T>,
        name:string,
        url:string,
        callback?:(data?:any)=>T) : Promise<T> {
        if (id) {
            if (!loadingMap.get(id)) {
                if (!objectMap.get(id)) {
                    loadingMap.set(id,{waiting:[]});
                    console.log("retrieving "+name+" new '"+id+"'");
                    return xhr.get(url, {timeout:30000})
                        .then(l => {
                            objectMap.set(id,l.data && l.data.length ? (callback? callback(l.data[0]) : l.data[0]) : undefined);
                            loadingMap.get(id)?.waiting.forEach((el) => {
                                console.log("finished waiting for "+name+" retrieved",id,el);
                                el.resolve(objectMap.get(id));
                            });
                            loadingMap.delete(id);
                            return objectMap.get(id)!;
                        })
                        .catch(error => {
                            console.log(error);
                            if (loadingMap.get(id)) {
                                loadingMap.get(id)?.waiting.forEach((el) => {
                                    el.reject({});
                                });
                                loadingMap.delete(id);
                            }
                            return Promise.reject(error);
                        });
                }
                console.log("retrieving "+name+" from cache",id);
                return Promise.resolve(objectMap.get(id)!);
            }
            else {
                return new Promise<T>((resolve,reject) => {
                    if (objectMap.get(id)) {
                        console.log("finished waiting for "+name+" retrieved",id);
                        resolve(objectMap.get(id)!);
                    }
                    loadingMap.get(id)?.waiting.push({resolve:resolve,reject:reject});
                });
            }
        }
        console.log(name+" undefined",id);
        return Promise.reject("no "+name);
    }
}