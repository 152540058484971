
export default class CacheMap<T> {

  private storage: Map<string,T>;

  constructor(private capacity: number = Infinity) {
      this.storage =  new Map();
  }

  public size(): number {
    return this.storage.size;
  }

  public set(key:string, value:T) {
      if (value) {
        if (this.storage.size === this.capacity) {
            this.storage.delete(this.storage.keys().next().value!);
            //console.log("after oldest cache delete:",this.storage);
        }
        this.storage.set(key,value);
        //console.log("set new cache object:",value);
    }
  }

  public get(key:string):T|undefined {
    return this.storage.get(key);
  }

  public delete(key:string):boolean {
    return this.storage.delete(key);
  }
}