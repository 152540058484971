import React, {useState} from 'react';
import {Navbar, Container} from 'react-bootstrap';
import AuthMethodPicker from '../authentication/AuthMethodPicker';
import {useTranslation} from "react-i18next";

const layout = (content: any) => (<Container fluid={true} className="p-3 layout">
    <Navbar bg="dark" variant="dark" className={"px-3"}>
        <Navbar.Brand>
            <img alt="" src="/exite.svg" height="32" className="d-none d-md-inline-block align-top"/>
            <img alt="" src="/exitex.svg" height="32" className="d-inline-block d-md-none align-top"/>
            &nbsp;WebEDI
        </Navbar.Brand>
    </Navbar>
    <div className={"jumbotron"}>{content}</div>
</Container>);


export const ExpiredSession = () => {

    const i18n = useTranslation();
    const [expiredSessionPicker, setExpiredSessionPicker] = useState<boolean>(false)

    const authenticate = () => {
        setExpiredSessionPicker(true);
    }

    return <>
        {layout(<h5>{i18n.t("ErrorPage.SessionExpiredMessage")}
            <button onClick={authenticate}>{i18n.t("ErrorPage.SessionExpiredLinkTitle")}</button>
        </h5>)}
        {expiredSessionPicker ? <AuthMethodPicker/> : null};
    </>;
}

export const LoadingProblem = () => {
    const i18n = useTranslation();
    return layout(<h5>{i18n.t("ErrorPage.UnableLoadingMessage")} <a
        href=".">{i18n.t("ErrorPage.UnableLoadingLinkTitle")}</a></h5>);
}