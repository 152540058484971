import React, { Component } from 'react';
import { Row,Col,Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {Trans} from 'react-i18next';

interface Props {
}

interface State {
}

export default class NotFound extends Component<Props, State> {

	render() {
		return(
		<>
		<br/>
		<Row>
    		<Col></Col>
    		<Col xs={6}>
				<Alert variant="warning">
				<Alert.Heading><Trans i18nKey="NotFound.Heading"/></Alert.Heading>
				<p>
					<Trans i18nKey="NotFound.Message"/>
				</p>
				<hr />
				<p className="mb-0">
					<Link className='btn btn-warning' to="/"><Trans i18nKey="NotFound.Button"/></Link>
				</p>
				</Alert>
			</Col>
    		<Col></Col>
  		</Row></>);
	}
}